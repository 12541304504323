<template>
    <div class="position-relative">
        <SubHeader :title="title" :link="'/signin'" :alert="false" :bookmark="false" />
        <Loading v-if="loading"/>
        <div class="px-3">
            <div class="text-center mt-5 mb-4">
                <span>다른 사이트와 중복되지 않은<br>안전한 비밀번호를 설정해 주세요.</span>
            </div>
            
            <div class="form-group mt-3">
                <input class="form-control thick mb-2" type="password" required placeholder="새 비밀번호" v-model="new_password" @keyup="CheckPassword()">
                <p class="text-gray-relative-600 mb-3 small" v-if="pw_chk">
                    <span class="small text-danger">- 비밀번호 규칙을 확인해주세요.</span>
                </p>
                <input class="form-control thick" type="password" required placeholder="새 비밀번호 확인" v-model="new_password_confirm" @keyup="CheckMathPassword()">
            </div>
            <p class="text-gray-relative-600 mb-2 small">
              <span class="small">- 영문, 숫자,툭수문자 한개이상을 혼합하여 6~20자로 사용 가능합니다</span>
            </p>
            <p class="text-danger mb-2 small" v-if="pw_match_chk === false">
                <span class="small">- 비밀번호가 일치하지 않습니다</span>
            </p>
            <div @click="ChangePassword()" class="btn btn-main thick text-white mt-4 w-100">재설정</div>
        </div>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import Loading from '@/components/common/Loading.vue'

const CryptoJS = require("crypto-js");

export default {
    components:{
        SubHeader,Loading
    },
    data(){
        return{
            code: this.$route.params.code,
            title: '비밀번호 재설정',
            loading: false,
            new_password: '',
            new_password_confirm: '',
            pw_match_chk : null,
            pw_chk : null,
        }
    },
    methods:{
        CheckPassword(){
            const password = this.new_password;
            var passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{6,20}$/;

            if (!passwordRegex.test(password)) {
                this.pw_chk = true;
            }else{
                this.pw_chk = false;
            }

        },
        CheckMathPassword() {
            

            const password = this.new_password;
            const password_confirm = this.new_password_confirm;


            if (password != password_confirm) {
                this.pw_match_chk = false;
            } else {
                this.pw_match_chk = true;
            }
        },
        ChangePassword(){
            
            const code  = this.code;
            const password = this.new_password;
            const password_confirm = this.new_password_confirm;

            if(password ==''){
                this.$alert('<i class="fal fa-exclamation-circle fa-2x mb-2 text-danger"></i><p class="small mt-1">비밀번호를 입력해주세요.</p>')
                return false;
            }

            if(password != password_confirm){
                this.$alert('<i class="fal fa-exclamation-circle fa-2x mb-2 text-danger"></i><p class="small mt-1">비밀번호를 확인해주세요.</p>')
                this.pw_match_chk = false;
                return false;
            }

            
            const body = {code,password};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/find/ChangePassword',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        
                        if(res.data.code =="200"){
                            this.$alert('<i class="fal fa-exclamation-circle fa-2x mb-2 text-danger"></i><p class="small mt-1">비밀번호를 재설정 하였습니다.</p>').then(
                                ()=>{
                                    this.$router.replace({path:'/signin'});
                                }
                            )
                            
                        }
                    }
                }
            );
        }
        // ChangePassword(){            
        //     this.$router.push('/signin')
        // }
    },
    mounted(){
        this.$EventBus.$emit('HideNav')
    },
    destroyed(){
        this.$EventBus.$emit('ShowNav')
    }
}
</script>