<template>
    <div class="position-relative">
        <Loading v-if="loading"/>
        <div class="text-center mt-5 mb-3" v-if="show">
            <span>휴대전화번호 정보와 일치하는 아이디입니다.</span>
        </div>
        <div class="text-center mt-5 mb-3" v-if="show ==false">
            <span>정보를 확인해주세요.</span>
        </div>
        <div class="p-4" v-if="show">
            <div class="border p-4">
                <ul>
                    <li class="mb-2">
                        <span class="text-gray-600 me-3">아이디</span> {{email}}
                    </li>
                    <li>
                        <span class="text-gray-600 me-3">가입일</span> {{join_date}}
                    </li>
                </ul>
            </div>
            <div class="flex-between-center w-100 mt-4">
                <router-link to="/signin" class="btn thick w-100 bg-main text-white me-1">확인</router-link>
                <router-link :to="`/change-pw/${code}`" class="btn thick w-100 border border-main text-main">비밀번호 재설정</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import Loading from '@/components/common/Loading.vue'

const CryptoJS = require("crypto-js");

export default {
    components:{
        SubHeader,Loading
    },
    data(){
        return{
            show : false,
            loading: false,
            email: '',
            join_date: '',
            code: this.$route.params.code,
        }
    },
    mounted(){
        this.$EventBus.$emit('HideNav')
        this.GetMemberInfo();
    },
    destroyed(){
        this.$EventBus.$emit('ShowNav')
    },
    methods:{
        GetMemberInfo(){
            
            const code = this.code;
            
            const body = {code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/find/GetMemberInfo',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            const email = body.email;
                            const join_date = body.join_date;

                            this.email = email;
                            this.join_date = join_date;
                            this.show = true;
                            
                        }else if(res.data.code =="100"){
                            this.show = false;
                            this.$alert('<i class="fal fa-exclamation-circle fa-2x mb-2 text-danger"></i><p class="small mt-1">가입 정보가<br>존재하지 않습니다.</p>')
                        }
                    }
                }
            );
        }
    },
    
}
</script>