<template>
    <div class="position-relative">
        <Loading v-if="loading"/>
        <div class="sign_box vstack gap-1 w-100 px-3 mt-5">
            <div class="form-group">
                <input required type="email"  class="form-control thick bg-body text-body border-gray-relative-300" v-model="email" placeholder="가입된 아이디 입력(example@email.com)"  :disabled="send_check">
            </div>
            <div class="form-group flex-between-center">
                <input required type="number" class="form-control thick bg-body text-body border-gray-relative-300 me-2" v-model="phone" placeholder="휴대전화번호 입력('-'제외)" :disabled="send_check">
                <button class="w-px-120 btn border border-main text-main flex-shrink-0 fs-px-14 h-px-43" @click="SendAuthSmsEmail()" v-if="send_check ==false">인증번호 전송</button>
                <button class="w-px-120 btn border border-main text-main flex-shrink-0 fs-px-14 h-px-43" @click="ResetPhone()" v-if="send_check ==true">다시 입력하기</button>
            </div>
            <div class="form-group flex-between-center mt-2 mb-0" v-if="send_check">
                <input required type="number"  class="form-control thick bg-body text-body border-gray-relative-300 me-2" v-model="code" placeholder="인증번호 입력">
                <button class="w-px-120 btn border border-main text-main flex-shrink-0 fs-px-14 h-px-43" @click="ConfirmAuthSmsEmail()">확인</button>
            </div>
            <div class="mx-2" v-if="show_validation_msg">
                <small class="text-gray-600">인증번호를 입력해주세요.</small>
            </div>
            <div @click="FindId()" class="btn thick text-white mt-4" :class="CheckConfirm()">비밀번호 찾기</div>
        </div>
        
        <!-- <div class="text-center">
            <i class="fal fa-exclamation-circle fa-3x mb-3 text-danger"></i>
            <p class="small">
                사용자 이메일 계정이<br>
                존재하지 않습니다.
            </p>
        </div>

            <router-link class="btn btn-primary w-100 mt-4 thick small" to="/signin">로그인 하기</router-link>
        <div class="text-center">
        </div> -->
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import Loading from '@/components/common/Loading.vue'

const CryptoJS = require("crypto-js");

export default {
    components:{
        SubHeader,Loading
    },
    data(){
        return{
            email: '',
            phone: '',
            code: '',
            loading : false,
            show_validation_msg: false,
            certi_confirm : false,
            send_check : false,
        }
    },
    methods:{
        CheckConfirm(){
            if(this.certi_confirm ==true){
                return "btn-main";
            }else{
                return "btn-secondary";
            }
            
        },
        ResetPhone(){
            this.send_check  = false;
            this.certi_confirm = false;
            this.phone ='';
            this.code = '';
            this.email = '';
        },
        SendAuthSmsEmail(){
            const phone = this.phone;
            const email = this.email;
            if(phone ==''){
                this.$alert('휴대폰 번호를 입력해주세요.');
                return false;
            }
            
            if(email ==''){
                this.$alert('가입된 아이디를 입력해주세요.');
                return false;
            }
            
            const body = {phone,email};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/find/SendAuthSmsEmail',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        
                        if(res.data.code =="200"){
                            this.send_check = true;
                            this.certi_confirm = false;
                            this.code = '';
                            this.$alert(`<i class="fal fa-check-circle fa-2x mb-2 text-success"></i><p class="small mt-1">인증코드를 <br>${phone}으로<br>발송되었습니다.</p>`)
                        }else if(res.data.code =="100"){
                            this.$alert('<i class="fal fa-exclamation-circle fa-2x mb-2 text-danger"></i><p class="small mt-1">가입 정보가<br>존재하지 않습니다.</p>')
                        }
                    }
                }
            );
        },
        ConfirmAuthSmsEmail(){
            this.show_validation_msg = false;
            const phone = this.phone;
            const code = this.code;
            const email = this.email;
            if(phone ==''){
                this.$alert('휴대폰 번호를 입력해주세요.');
                return false;
            }

            if(email ==''){
                this.$alert('가입된 아이디를 입력해주세요.');
                return false;
            }

            if(code ==''){
                this.$alert('인증코드를 입력해주세요.');
                return false;
            }
            
            
            const body = {phone,code,email};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/find/ConfirmAuthSmsEmail',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            const mem_code = body.mem_code;
                            this.mem_code = mem_code
                            this.certi_confirm = true;
                            // this.send_check = false;
                            
                        }else if(res.data.code =="300"){
                            this.show_validation_msg = true;
                        }else if(res.data.code =="100"){
                            this.$alert('<i class="fal fa-exclamation-circle fa-2x mb-2 text-danger"></i><p class="small mt-1">가입 정보가<br>존재하지 않습니다.</p>')
                        }
                    }
                }
            );
        },
        FindId(){
            if(this.certi_confirm){
                const mem_code = this.mem_code;
                this.$router.push(`/change-pw/${mem_code}`)
            }else{
                this.$alert('<i class="fal fa-exclamation-circle fa-2x mb-2 text-danger"></i><p class="small mt-1">인증후 진행해주세요.</p>')
            }
            
        },
    },
    mounted(){
        this.$EventBus.$emit('HideNav')
    },
    destroyed(){
        this.$EventBus.$emit('ShowNav')
    }
}
</script>

<style lang="scss" scoped>
    .find_email{
        height: 100vh;
        min-height: 500px;
    }

    .form-control{
        font-weight: bold;

        &::placeholder{
            font-weight: normal;
        }
    }

</style>