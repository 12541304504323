<template>
    <div class="position-relative">
        <SubHeader :title="title" :link="'/signin-email'" :alert="false" :bookmark="false" />
        <Loading v-if="loading"/>
        <div>
            <div class="flex-between-center w-100">
                <router-link class="text-center border-bottom border-width-px-2 w-50 py-3" to="/find-id" :class="{'border-main':selected_idx==0}">
                    <span class="fw-bold text-gray-500" :class="{'text-main':selected_idx==0}">아이디 찾기</span>
                </router-link>
                <router-link class="text-center border-bottom border-width-px-2 w-50 py-3" to="/find-pw" :class="{'border-main':selected_idx==1}">
                    <span class="fw-bold text-gray-500" :class="{'text-main':selected_idx==1}">비밀번호 찾기</span>
                </router-link>
            </div>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import Loading from '@/components/common/Loading.vue'

const CryptoJS = require("crypto-js");

export default {
    components:{
        SubHeader,Loading
    },
    data(){
        return{
            title: '아이디 / 비밀번호 찾기',

            loading : false,
            selected_idx: 0,
        }
    },
    methods:{
    },
    watch:{
       $route(to, from) {
        
        if (to.path != from.path) {
            if( to.path == '/find-id' ){
                this.selected_idx = 0;
            }else if( to.path == '/find-pw' ){
                this.selected_idx = 1;
            }
        }
      }
    },
    mounted(){
        this.$EventBus.$emit('HideNav');

        const path = this.$route.path;
        if( path == '/find-id' ){
            this.selected_idx = 0;
        }else if( path == '/find-pw' ){
            this.selected_idx = 1;
        }
    },
    destroyed(){
        this.$EventBus.$emit('ShowNav')
    }
}
</script>

<style lang="scss" scoped>
    .find_email{
        height: 100vh;
        min-height: 500px;
    }

    .form-control{
        font-weight: bold;

        &::placeholder{
            font-weight: normal;
        }
    }

</style>